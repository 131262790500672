module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Enhance Your Website's Potential with Mentor","titleTemplate":"%s | Mentor Gatsby Chakra UI Template","description":"Elevate website with Mentor: The premium Gatsby template and Chakra UI framework for ease and impact. The ultimate solution for websites looking to stand out.","language":"en-US","canonical":"https://mentor.themebiotic.com","openGraph":{"type":"website","locale":"en_US","url":"https://mentor.themebiotic.com","site_name":"Mentor"},"twitter":{"handle":"@themebiotic","site":"@themebiotic","cardType":"summary_large_image"}},
    },{
      plugin: require('../../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":false,"options":{"siteUrl":"https://mentor.themebiotic.com","basePath":"/"}},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2850,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent","showCaptions":false,"disableBgImageOnAlpha":true,"withWebp":{"quality":80},"withAvif":{"quality":80},"srcSetBreakpoints":[480,768,960,1200,1425],"tracedSVG":{"threshold":120}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.33,"once":true,"easing":"ease-out-quart"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Fraunces:400,500,600,700,800","Mulish:200,300,400,500,600,700,800&display=swap"]}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-mentor-personal","short_name":"mentor","start_url":"/","description":"Mentor is a visually appealing Gatsby.js template that takes advantage of the capabilities of Chakra UI to generate a contemporary and sophisticated website.","lang":"en","display":"minimal-ui","background_color":"#170ED8","icon":"assets/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b25ca017b445987f022a943e7be3cbc"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
