const brand = {
  50: "#D9EBFF",
  100: "#A3C1FF",
  200: "#6C8CFF",
  300: "#3F54F7",
  400: "#2422DD",
  500: "#170ED8",
  600: "#0E00CC",
  700: "#0800B3",
  800: "#050099",
  900: "#030080",
};

const primary = {
  50: "#FFD9FF",
  100: "#FFA3FC",
  200: "#FF6CDD",
  300: "#F73FB0",
  400: "#DD227E",
  500: "#D80E6A",
  600: "#CC0056",
  700: "#B30043",
  800: "#990030",
  900: "#80001F",
};

const secondary = {
  50: "#D9FFD9",
  100: "#A3FFA5",
  200: "#6CFF8F",
  300: "#3FF785",
  400: "#22DD81",
  500: "#0ED87B",
  600: "#00CC76",
  700: "#00B370",
  800: "#009969",
  900: "#008060",
};

const tertiary = {
  50: "#FFEDD9",
  100: "#FFE1A3",
  200: "#FFE06C",
  300: "#F7E23F",
  400: "#DBDD22",
  500: "#CFD80E",
  600: "#BFCC00",
  700: "#ABB300",
  800: "#949900",
  900: "#7C8000",
};

export default {
  brand,
  primary,
  secondary,
  tertiary,
};
