exports.components = {
  "component---packages-gatsby-mentor-core-src-templates-page-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-pages-changelog-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/page-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/pages/changelog.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-page-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-pages-changelog-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-page-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-pages-style-guide-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/page-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/pages/style-guide.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-page-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-pages-style-guide-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-page-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-pages-terms-of-service-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/page-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/pages/terms-of-service.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-page-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-pages-terms-of-service-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-5-tipsto-improve-your-website-design-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/5-tipsto-improve-your-website-design/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-5-tipsto-improve-your-website-design-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-best-practicesfor-cyber-security-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/best-practicesfor-cyber-security/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-best-practicesfor-cyber-security-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-common-mythsabout-cyber-security-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/common-mythsabout-cyber-security/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-common-mythsabout-cyber-security-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-choosethe-right-technologyfor-your-business-needs-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/howto-choosethe-right-technologyfor-your-business-needs/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-choosethe-right-technologyfor-your-business-needs-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-choosethe-right-web-ui-frameworks-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/howto-choosethe-right-web-ui-frameworks/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-choosethe-right-web-ui-frameworks-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-createa-powerful-visual-identity-through-app-design-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/howto-createa-powerful-visual-identity-through-app-design/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-createa-powerful-visual-identity-through-app-design-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-implement-technology-into-your-business-processes-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/howto-implement-technology-into-your-business-processes/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-implement-technology-into-your-business-processes-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-optimize-your-websitefor-mobile-devices-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/howto-optimize-your-websitefor-mobile-devices/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-howto-optimize-your-websitefor-mobile-devices-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-optimizing-app-designfor-different-platforms-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/optimizing-app-designfor-different-platforms/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-optimizing-app-designfor-different-platforms-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-six-tipsfor-keeping-your-data-secure-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/six-tipsfor-keeping-your-data-secure/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-six-tipsfor-keeping-your-data-secure-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-smart-city-and-iot-mobile-app-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/smart-city-and-iot-mobile-app/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-smart-city-and-iot-mobile-app-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-the-essential-guideto-cyber-security-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/the-essential-guideto-cyber-security/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-the-essential-guideto-cyber-security-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-top-5-ux-trendsfor-app-designin-2023-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/top5-ux-trendsfor-app-designin2023/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-top-5-ux-trendsfor-app-designin-2023-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-what-you-needto-know-about-web-accessibility-standards-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/post-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/posts/what-you-needto-know-about-web-accessibility-standards/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-post-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-posts-what-you-needto-know-about-web-accessibility-standards-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-tag-query-jsx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/tag-query.jsx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-tag-query-jsx" */),
  "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-comprehensive-brand-identity-strategy-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/work-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/works/comprehensive-brand-identity-strategy/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-comprehensive-brand-identity-strategy-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-getting-user-feedback-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/work-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/works/getting-user-feedback/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-getting-user-feedback-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-landing-page-for-an-online-store-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/work-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/works/landing-page-for-an-online-store/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-landing-page-for-an-online-store-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-revamping-brands-online-presence-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/work-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/works/revamping-brands-online-presence/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-revamping-brands-online-presence-index-mdx" */),
  "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-the-powerof-minimalismin-web-design-index-mdx": () => import("./../../../../packages/gatsby-mentor-core/src/templates/work-query.jsx?__contentFilePath=/vercel/path0/gatsby/web/content/works/the-powerof-minimalismin-web-design/index.mdx" /* webpackChunkName: "component---packages-gatsby-mentor-core-src-templates-work-query-jsx-content-file-path-vercel-path-0-gatsby-web-content-works-the-powerof-minimalismin-web-design-index-mdx" */),
  "component---packages-gatsby-theme-mentor-src-themebiotic-gatsby-mentor-core-templates-blog-query-jsx": () => import("./../../../../packages/gatsby-theme-mentor/src/@themebiotic/gatsby-mentor-core/templates/blog-query.jsx" /* webpackChunkName: "component---packages-gatsby-theme-mentor-src-themebiotic-gatsby-mentor-core-templates-blog-query-jsx" */),
  "component---packages-gatsby-theme-mentor-src-themebiotic-gatsby-mentor-core-templates-works-query-jsx": () => import("./../../../../packages/gatsby-theme-mentor/src/@themebiotic/gatsby-mentor-core/templates/works-query.jsx" /* webpackChunkName: "component---packages-gatsby-theme-mentor-src-themebiotic-gatsby-mentor-core-templates-works-query-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

